<template>
  <div id="app">
    <navbar :storage_costumer="storage_costumer" />
    <router-view></router-view>
    <footer-component />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  /* padding: 30px; */
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
import navbar from "@/views/section/navbar";
import footerComponent from "@/views/section/footer_component";

export default {
  data() {
    return {
      storage_costumer: JSON.parse(localStorage.getItem("customer")),
    };
  },
  watch: {
    $route: {
      // set header tab title
      immediate: true,
      handler(to, from) {
        this.storage_costumer = JSON.parse(localStorage.getItem("customer"));
      },
    },
  },
  mounted() {},
  components: {
    navbar,
    footerComponent,
  },
};
</script>
