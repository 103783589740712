import Vue from 'vue'
import router from '../router';
import helperUmum from "@/helper/function_umum";

class auth {
    async logout() {
        let url_helper = helperUmum.urlPublic();

        let formData = new FormData();
        let token = localStorage.getItem("token")
        await axios
            .post(`${url_helper}/api/user/logout`, formData, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then((response) => {
            })
            .catch((errors) => {
            });
        this.removeStorage();
        router.push({ name: 'signin' });
    }

    setStorage(res) {
        let token = res.data.token.access_token
        let expiresIn = res.data.token.expired
        let token_type = res.data.token.token_type
        let customer = res.data.customer

        localStorage.setItem('token', token);
        localStorage.setItem('expiresIn', expiresIn);
        localStorage.setItem('customer', JSON.stringify(customer));
        localStorage.setItem('token_type', token_type);
    }

    removeStorage() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('expiresIn');
        window.localStorage.removeItem('token_type');
        window.localStorage.removeItem('customer');
    }

}



export default auth = new auth();
